import React from "react";
import styled from "styled-components";
import ResourceImage from "components/ResourceImage";

const ConditionActor = styled.div`
  width: 100px;
  height: 200px;
  display: block;
  overflow: hidden;
  margin-right: 2rem;

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 50%;
  }

  .actorTitle {
    text-align: center;
    font-weight: bold; 
    margin-top: 8px;
    font-size: 14px;
  }
  .actorTitleRole {
    text-align: center;
    margin-top: 2px;
    font-style: italic;
    font-size: 13px;
  }
`;

const PlayerDetails = (props) => {
 
  const getActorIconPng = () => {
    if (!props.condition || !props.condition.Title) return "None.png";
    return `${props.condition.Title.replace(/[\s.]/g, "")}.png`;
  };

  return (
    <ConditionActor>
      <ResourceImage alt={props.condition?.Title || ""} png={getActorIconPng()} />
      {props.condition?.Title && (
        <div className="actorTitle">{props.condition.Title}</div>
      )}
      {props.condition?.TitleRole &&
        props.condition.Title !== props.condition.TitleRole && (
          <div className="actorTitleRole">{props.condition.TitleRole}</div>
        )}
    </ConditionActor>
  );
};

export default PlayerDetails;
