import React, { useEffect } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import { Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import isExternal from "is-url-external";
import classNames from "classnames";
import * as menus from "./_nav";
import SidebarMinimizer from "./components/SidebarMinimizer";
import { connect } from "react-redux";
import { updateFirstLevelTabIndex } from "../../redux/UI/actions";
import "./Sidebar.scss";

const Sidebar = (props) => {
  // toggling the sideBar folders/subfolders
  const handleNavDropDownClick = (e) => {
    e.preventDefault();

    const openedClass = document.getElementsByClassName("nav-dropdown open");

    [...openedClass].forEach((element) => {
      if (
        e.target.parentElement.classList.contains("main-category") &&
        element.classList.contains("main-category") &&
        !e.target.parentElement.isEqualNode(element)
      ) {
        element.classList.toggle("open");
      } else {
        if (
          !element.classList.contains("main-category") &&
          !e.target.parentElement.isEqualNode(element)
        ) {
          element.classList.toggle("open");
        }
      }      
    });

    e.target.parentElement.classList.toggle("open");

    assignFirstLevelTabIndexes();
  };

  const activeRoute = (itemName) => {
    const parentSlug = `/${itemName.toLowerCase()}`;

    return props.location.pathname.indexOf(parentSlug) > -1
      ? "nav-item nav-dropdown open"
      : "nav-item nav-dropdown";
  };

  const shouldDisplayProjectMenu = () => {
    const currentServer = window.location.href;
    console.log(currentServer);
    if (currentServer.includes("test") || currentServer.includes("localhost")) {
      return true;
    }
    return true;
  };

  const renderMenuOptions = () => {
    if (props.location.pathname === "/welcome") {
      return menus.welcomeMenu.items;
    }

    if (props.projectReadOnly === true) {
      return menus.reviewProjectMenu.items;
    } else if (props.isInstructor === true) {
      return menus.instructorMenu.items;
    } else if (props.noRunIdSelected === true) {
      return menus.noRunIdMenu.items;
    } else if (props.budgetIsLocked === true) {
      if (props.workWeekInProgress === true) {
        if (!shouldDisplayProjectMenu()) {
          return menus.workWeekInProgressMenu.items;
        } else {
          return menus.workWeekInProgressMenu.items;
        }
      }
      if (!shouldDisplayProjectMenu()) {
        return menus.postApprovalMenu.items.filter(
          (i) => i.url !== "/projects"
        );
      } else {
        return menus.postApprovalMenu.items;
      }
    }
    if (!shouldDisplayProjectMenu()) {
      return menus.preApprovalMenu.items.filter((i) => i.url !== "/projects");
    } else {
      return menus.preApprovalMenu.items;
    }
  };

  // simple wrapper for nav-title item
  const wrapper = (item) =>
    item.wrapper && item.wrapper.element
      ? React.createElement(
          item.wrapper.element,
          item.wrapper.attributes,
          item.name
        )
      : item.name;

  // nav list section title
  const navTitle = (title, key) => {
    const classes = classNames("nav-title", title.class);
    return (
      <li key={key} className={classes}>
        {wrapper(title)}{" "}
      </li>
    );
  };

  // nav list divider
  const navDivider = (divider, key) => <li key={key} className="divider" />;

  // nav item with nav link
  const navLink = (item, key) => {
    const classes = classNames(item.class);
    const variant = classNames(
      "nav-link",
      item.variant ? `nav-link-${item.variant}` : ""
    );

    const handleLinkClick = (e) => {
      props.updateFirstLevelTabIndex(e.target.tabIndex);
    };

    return (
      <NavItem key={key} className={classes}>
        {isExternal(item.url) ? (
          <RsNavLink href={item.url} activeClassName="active" onClick={handleLinkClick}>
            <i className={item.icon} />
            {item.name}
          </RsNavLink>
        ) : item.url === "/documentation" ||
          item.url === "/planning" ||
          item.url === "/execution" ? (
          <Link to={item.url} className={variant} activeclassname="active" onClick={handleLinkClick}>
            <i className={item.icon} />
            {item.name}
          </Link>
        ) : (
          <NavLink to={item.url} className={variant} activeclassname="active" onClick={handleLinkClick}>
            <i className={item.icon} />
            {item.name}
          </NavLink>
        )}
      </NavItem>
    );
  };

  // nav dropdown
  const navDropdown = (item, key) => (
    <li key={key} className={`${activeRoute(item.name)} ${item.class}`}>
      <a
        href="/null"
        className={`nav-link nav-dropdown-toggle`}
        onClick={handleNavDropDownClick}
      >
        <i className={item.icon} />
        {item.name}
      </a>
      <ul className="nav-dropdown-items">{navMainList(item.children)}</ul>
    </li>
  );

  // nav list
  const navMainList = (items) =>
    items.map((item, idx) =>
      item.title
        ? navTitle(item, idx)
        : item.divider
        ? navDivider(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navLink(item, idx)
    );

    const  assignFirstLevelTabIndexes = () =>{
    // Assign sequential tabIndex to <a> elements
    const links = document.querySelectorAll(".sidebar-nav a");
    links.forEach((link, index) => {
      link.tabIndex = (index + 1) * 100;
    });

    // Adjust tabIndex for <a> elements inside closed <li> elements with class "nav-dropdown"
    const dropdowns = document.querySelectorAll(".nav-dropdown");
    dropdowns.forEach((dropdown) => {
      if (!dropdown.classList.contains("open")) {
        const dropdownLinks = dropdown.querySelectorAll("a");
        dropdownLinks.forEach((link, index) => {
          //We skip the first a (index 0) because it is a nav-dropdown-toggle  
          if (index !== 0 || !link.classList.contains("nav-dropdown-toggle")) {
            link.tabIndex = -1;
          }
        });
      }
    });
  };

  useEffect(() => {
    assignFirstLevelTabIndexes();
  });

  // sidebar-nav root
  return (
    <div className="sidebar">
      <nav className="sidebar-nav" tourstop="sidebar">
        <Nav>{navMainList(renderMenuOptions())}</Nav>
      </nav>
      <SidebarMinimizer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  firstLevelTabIndex: state.ui.firstLevelTabIndex
});

const mapDispatchToProps = {
  updateFirstLevelTabIndex
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
