import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _debounce from "lodash.debounce";
import styled from "styled-components";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import CardHeaderBold from "components/styled/CardHeaderBold";
import history from "../../history";

import {
  StakeholderInteraction,
  NoWork
} from "routes/Report/components/History";
import {
  runTheWeek,
  getPresentationRunDay,
  processConditionResponse,
  processReassignResponse,
  checkForStaffingProblems
} from "redux/WorkWeek/thunks";
import { fetchRuns } from "redux/Authentication/thunks";
import { resetWorkWeek } from "redux/WorkWeek/actions";
import {
  processAssignments,
  acceptWeeklyStaffingPlan
} from "redux/Project/thunks";
import { getCurrentRunInfo } from "redux/Project/selectors";
import TestPanel from "routes/Settings/components/testing/TestPanel";
import WorkWeekInfo from "./components/WorkWeekInfo";
import ConditionPanel from "./components/ConditionPanel";
import ReassignPanel from "./components/ReassignPanel";
import AssistantPanel from "./components/AssistantPanel";
import { AlertMessage } from "./components/AlertMessage";
import "./WorkWeek.css";

import {
  DailyWork,
  Meeting,
  Conference,
  DelayedTask,
  StaffingUpdate,
  Absent,
  MissedEvent,
  TaskComplete,
  Release,
  Reassign,
  VoluntaryOvertime,
  TeamTraining,
  IndvTraining,
  TeamReward,
  IndvReward,
  TaskWillBeDelayed
} from "./components";
import IdleWorker from "routes/Report/components/History/Components/IdleWorker";
import { fetchClassRuns } from "redux/Instructor/thunks";

const ScrollDiv = styled.div`
  flex: 1;
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 400px;
`;

class WorkWeek extends React.Component {
  state = {
    continueButtonEnabled: false,
    conditionId: this.props.conditionId,
    selectedResponse: 0,
    cardInfoOpen: false,
    pctComplete: 0,
    hideFullReportLink: true,
    runWeekButtonDisabled: true,
    resumingInterruptedRun: false,
    studentIsStopped: false,
    initialLoad: true,
    currentRun: {}
  };

  componentDidMount = async () => {
    this.setState({
      currentRun: this.props.currentRunInfo.currentRun,
      resumingInterruptedRun:
        this.props.isRunningDay === true ||
        (this.props.runDay > 0 && this.props.weekHasCompleted === false)
    });
    // Remove the Info and Budget Item toggle links from the PageHeader
    const PageHeaderEl = document.querySelector(".component-PageHeader");
    if (
      PageHeaderEl &&
      PageHeaderEl.classList.contains("route-PlanningChild")
    ) {
      PageHeaderEl.classList.remove("route-PlanningChild");
    }

    // Scroll to the top when this route mounts
    window.scrollTo(0, 0);
    let currentWeek = this.props.currentProjectWeek;
    await this.props.checkForStaffingProblems(this.props.runId, currentWeek);

    await this.props.getPresentationRunDay(this.props.runId);

    await this.props.fetchRuns(this.props.userId);

    if (
      this.props &&
      this.props.currentRunInfo !== undefined &&
      this.props.currentRunInfo !== null &&
      this.props.currentRunInfo.message === "ok" &&
      this.props.currentRunInfo.currentRun.CanRun !== undefined &&
      this.props.currentRunInfo.currentRun.CanRun === false
    ) {
      this.setState({
        runWeekButtonDisabled: true
      });
    } else if (
      this.props &&
      this.props.currentRunInfo !== undefined &&
      this.props.currentRunInfo !== null &&
      this.props.currentRunInfo.message === "ok" &&
      this.props.currentRunInfo.currentRun.StopWeeks !== undefined &&
      this.props.currentRunInfo.currentRun.StopWeeks.filter(
        (s) =>
          s.RunWeek === this.props.currentProjectWeek - 1 &&
          s.StopOnWeek === true
      ).length > 0
    ) {
      this.setState({
        runWeekButtonDisabled: true
      });
    } else if (this.props.haveStaffingProblem) {
      this.setState({
        runWeekButtonDisabled: true
      });
    } else if (
      this.props.runStarted === false &&
      this.props.projectStatus.WeekHasCompleted === true
    ) {
      if (
        this.props.weekHasCompleted === true ||
        this.props.weekHasCompleted === undefined
      ) {
        currentWeek = this.props.displayProjectWeek;
      }
      this.setState({
        runWeekButtonDisabled: false
      });
    } else {
      // week is running and it's ok to run
      this.setState({
        runWeekButtonDisabled: false
      });
    }
    this.setState({
      initialLoad: false
    });
  };

  toggleInfo = () => {
    this.setState({ cardInfoOpen: !this.state.cardInfoOpen });
  };

  runTheWeek = _debounce(async () => {
    this.setState({
      runWeekButtonDisabled: true,
      resumingInterruptedRun: false
    });
    this.props.runTheWeek(
      this.props.runId,
      this.props.conditionId,
      this.props.runDay,
      this.props.currentRunWeek
    );
  }, 75);

  acknowledgeStaffingProblems = _debounce(() => {
    // if (!this.state.studentIsStopped) {
    this.setState({
      runWeekButtonDisabled: false
    });
    // }
  }, 75);

  selectCondition = _debounce((value) => {
    this.setState({
      selectedResponse: value
    });
  }, 75);

  selectReassign = (value) => {
    this.setState({
      selectedResponse: value
    });
  };

  submitConditionResponse = _debounce(() => {
    this.props.processConditionResponse(
      this.props.runId,
      this.props.condition.ConditionId,
      this.state.selectedResponse,
      this.props.currentRunWeek,
      this.props.runDay
    );
    this.setState({
      selectedResponse: 0
    });
    // this.props.currentRunWeek}... Current day is: {this.props.runDay}
  }, 75);

  submitReassignResponse = _debounce(() => {
    let taskId = "";

    if (parseInt(this.state.selectedResponse, 10) === 0) {
      // release from project
      // need to release person from project
      const worker = this.props.workerAssignmentList.find(
        (wa) => wa.WorkerId === this.props.condition.IdleWorkerId
      );

      Promise.all([
        this.props.processAssignments(worker, "Status", "Release", 0)
      ]).then(() =>
        this.runTheWeek(
          this.props.runId,
          this.props.condition.ConditionId,
          this.props.runDay,
          this.props.currentRunWeek
        )
      );
    } else {
      taskId =
        this.props.condition.ResponseList[this.state.selectedResponse][
          this.state.selectedResponse
        ];

      this.props.processReassignResponse(
        this.props.runId,
        this.props.condition.ConditionId,
        taskId,
        this.props.condition.IdleWorkerId,
        this.props.currentRunWeek,
        this.props.runDay
      );
    }
    this.setState({
      selectedResponse: 0
    });
  }, 75);

  showContent = () => {
    if (this.props && this.props.hasError) {
      return (
        <AlertMessage
          message={`An error has occurred in the work week.  Please stop your work and contact support@simulationpl.com.  Provide your username, your execution id and this error message: ${this.props.errorMessage}  stacktrace: ${this.props.errorStacktrace}`}
          color="danger"
        />
      );
    }
    if (
      this.props &&
      this.props.currentRunInfo !== undefined &&
      this.props.currentRunInfo !== null &&
      this.props.currentRunInfo.message === "ok" &&
      this.props.currentRunInfo.currentRun.CanRun === false
    ) {
      return (
        <AlertMessage
          message="Your instructor has disabled running the work week for now."
          color="info"
        />
      );
    }
    if (
      this.props &&
      this.props.currentRunInfo !== undefined &&
      this.props.currentRunInfo !== null &&
      this.props.currentRunInfo.message === "ok" &&
      this.props.currentRunInfo.currentRun.StopWeeks !== undefined &&
      this.props.currentRunInfo.currentRun.StopWeeks !== null &&
      this.props.currentRunInfo.currentRun.StopWeeks.filter(
        (s) =>
          s.RunWeek === this.props.currentProjectWeek - 1 &&
          s.StopOnWeek === true
      ).length > 0
    ) {
      return (
        <AlertMessage
          message={`Your instructor has stopped the work for this week.  Here is the message:
            ${
              this.props.currentRunInfo.currentRun.StopWeeks.find(
                (s) =>
                  s.RunWeek === this.props.currentProjectWeek - 1 &&
                  s.StopOnWeek === true
              ).StopMessage
            }`}
          color="info"
        />
      );
    }

    if (
      // !this.state.studentIsStopped &&
      this.props.haveStaffingProblem === true &&
      this.state.runWeekButtonDisabled === true
    ) {
      return (
        // ..runWeekButtonDisabled

        <span>
          <ScrollDiv key="staffing-problem">
            {this.props.staffingProblemList.map((row, index) => (
              <AlertMessage
                key={`am-problem-${index.toString()}`}
                message={row.Problem}
                color="warning"
              />
            ))}
          </ScrollDiv>
          <button
            onClick={(event) => {
              event.preventDefault();
              this.setState({ runWeekButtonDisabled: false });
            }}
            className="btn btn-primary"
            tabIndex={this.props.firstLevelTabIndex + 5}
          >
            Acknowledged
          </button>
        </span>
      );
    }

    if (!this.props.runStarted) {
      return (
        <AlertMessage
          message={`Waiting to begin work on week ${this.props.displayProjectWeek}`}
        />
      );
    }
    let iterKey = 1;
    if (this.props.haveCondition === true) {
      return (
        <div>
          <ConditionPanel
            runId={this.props.runId}
            condition={this.props.condition}
            onValueChange={this.selectCondition}
            selectedResponse={this.state.selectedResponse}
          />
          <footer className="text-left">
            <button
              onClick={this.submitConditionResponse}
              disabled={
                this.state.selectedResponse === 0 &&
                this.props.autorun === false
              }
              className="btn btn-primary btn-week-continue"
            >
              Continue Week #{this.props.displayProjectWeek}
              {/* // TODO check this */}
            </button>
          </footer>
        </div>
      );
    }
    if (this.props.haveReassign === true) {
      return (
        <div className="box-content">
          <div>
            <ReassignPanel
              runId={this.props.runId}
              condition={this.props.condition}
              onValueChange={this.selectCondition}
              selectedResponse={this.state.selectedResponse}
              submitResponse={this.submitReassignResponse}
            />
            <footer>
              <button
                onClick={this.submitReassignResponse}
                disabled={this.state.selectedResponse === 0}
                className="btn btn-primary btn-week-continue"
              >
                Continue Week #{this.props.displayProjectWeek}
                {/* // TODO check this */}
              </button>
            </footer>
          </div>
        </div>
      );
    }
    if (this.props.havePlayScript === true) {
      return (
        <div className="box-content">
          <div>
            <AssistantPanel
              runId={this.props.runId}
              playScriptList={this.props.playScriptList}
            />
            <footer>
              <button
                onClick={() => {
                  this.selectCondition(1);
                  this.setState({ continueButtonEnabled: false });
                  this.runTheWeek();
                }}
                disabled={this.state.continueButtonEnabled === true}
                className="btn btn-primary btn-week-continue"
              >
                Continue Week #{this.props.displayProjectWeek}
                {/* // TODO check this */}
              </button>
            </footer>
          </div>
        </div>
      );
    }

    if (this.props.runWeekResponseState === "eop") {
      return (
        <AlertMessage message="Congratulations, your project is complete!" />
      );
    }
    if (this.props.runWeekResponseState.toLowerCase() === "eow") {
      return <AlertMessage message="Work week complete!" />;
    }

    if (this.props.runWeekResponseState === "eod") {
      switch (this.props.runDay % 5) {
        case 0:
          return <AlertMessage message="Today is Friday" />;

        case 1:
          return <AlertMessage message="Today is Monday" />;
        case 2:
          return <AlertMessage message="Today is Tuesday" />;
        case 3:
          return <AlertMessage message="Today is Wednesday" />;
        case 4:
          return <AlertMessage message="Today is Thursday" />;
        default:
          return <AlertMessage message="Today is Friday" />;
      }
    } else if (
      this.state.resumingInterruptedRun === true &&
      this.props.runStarted === false
    ) {
      return (
        <AlertMessage
          message={`Your work on week: ${
            Math.floor((this.props.projectStatus.DayNumber - 1) / 5) + 1
          } seems to have been interrupted. Please click the continue button.`}
        />
      );
    } else {
      return <AlertMessage message="The team is working on the project." />;
    }
    //  }
  };

  enableContinueButton = () => {
    if (this.props && this.props.hasError) {
      return false;
    }
    if (this.state.selectedResponse === 0) {
      return false;
    }
    if (this.props.haveCondition === true) {
      return true;
    }
    if (this.props.haveReassign === true) {
      return true;
    }
    if (this.props.havePlayScript === true) {
      return true;
    }
    if (this.props.weekHasCompleted === false) {
      // TODO CHECK THIS
      return true;
    }
    return false;
  };

  renderButtonText = () => {
    let buttonTxt = "";

    // if (this.props.staffingProblemList && this.props.staffingProblemList.length > 0 && this.state.runWeekButtonDisabled === true ) {
    //   buttonTxt = "Check staffing..."
    // } else
    if (this.props && this.props.hasError) {
      buttonTxt = "Error";
    } else if (this.state.initialLoad === true) {
      buttonTxt = "Checking Staffing...";
    } else if (this.props.weekHasCompleted === false) {
      buttonTxt = `Continue Week: ${this.props.displayProjectWeek}`;
    } else if (this.props.runStarted === true) {
      buttonTxt = `Working Week #${this.props.displayProjectWeek}`;
    } else if (this.props.runWeekResponseState === "eop") {
      buttonTxt = "Project Complete";
    } else {
      buttonTxt = `Start Week #${this.props.displayProjectWeek}`; // TODO CHECK THIS
    }

    return buttonTxt;
  };

  render() {
    const renderPctCompleteWidth = () => {
      const pctString = `${this.props.workWeekPctComplete.toString()}%`;

      return pctString;
    };
    const renderChildren = (dayOfWeek) => {
      if (
        this.props.historySummary === undefined ||
        this.props.historySummary === null
      ) {
        return [];
      }

      const children = [];
      let item = {};
      let comp = {};
      let keys = [];

      keys = Object.keys(this.props.historySummary);

      for (let i = 0; i < keys.length; i++) {
        item = this.props.historySummary[i];

        if (item.DayOfWeek === dayOfWeek) {
          let cKeys = [];
          cKeys = Object.keys(item.ComponentList);
          for (let j = 0; j < cKeys.length; j++) {
            comp = item.ComponentList[j];
            if (comp.ComponentName === "dailywork") {
              children.push(
                <li className="report-Item item-DailyWork" key={(i += 1)}>
                  <i className="fa fa-folder-open-o" />
                  <DailyWork key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "meeting") {
              children.push(
                <li className="report-Item item-Meeting" key={(i += 1)}>
                  <i className="fa fa-handshake-o" />
                  <Meeting key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "conference") {
              children.push(
                <li className="report-Item item-Conference" key={(i += 1)}>
                  <i className="fa fa-calendar-o" />
                  <Conference key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "stakeholderinteraction") {
              children.push(
                <li className="report-Item item-Stakeholder" key={(i += 1)}>
                  <i className="fa fa-calendar-o" />
                  <StakeholderInteraction
                    key={(i += 1)}
                    properties={comp.Properties}
                  />
                </li>
              );
            } else if (comp.ComponentName === "nowork") {
              children.push(
                <li className="report-Item item-DelayedTask" key={(i += 1)}>
                  <i className="fa fa-paper-plane-o" />
                  <NoWork key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "delayedTask") {
              children.push(
                <li className="report-Item item-DelayedTask" key={(i += 1)}>
                  <i className="fa fa-paper-plane-o" />
                  <DelayedTask key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "taskwillbedelayed") {
              children.push(
                <li className="report-Item item-DelayedTask" key={(i += 1)}>
                  <i className="fa fa-paper-plane-o" />
                  <TaskWillBeDelayed
                    key={(i += 1)}
                    properties={comp.Properties}
                  />
                </li>
              );
            } else if (
              comp.ComponentName === "staffing" &&
              comp.Properties.worker !== "NONE"
            ) {
              children.push(
                <li className="report-Item item-Staffing" key={(i += 1)}>
                  <i className="fa fa-users" />
                  <StaffingUpdate key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "absent") {
              children.push(
                <li className="report-Item item-Absent" key={(i += 1)}>
                  <i className="fa fa-stethoscope" />
                  <Absent
                    key={(i += 1)}
                    properties={comp.Properties}
                    showImage={false}
                  />
                </li>
              );
            } else if (
              comp.ComponentName === "missedmtg" ||
              comp.ComponentName === "missedmtgchg" ||
              comp.ComponentName === "missedconf"
            ) {
              children.push(
                <li className="report-Item item-Missed" key={(i += 1)}>
                  <i className="fa fa-calendar-times-o" />
                  <MissedEvent key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "taskcomplete") {
              children.push(
                <li className="report-Item item-TaskComplete" key={(i += 1)}>
                  <i className="fa fa-check-square-o" />
                  <TaskComplete key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "release") {
              children.push(
                <li className="report-Item item-Release" key={(i += 1)}>
                  <i className="fa fa-user-times" />
                  <Release key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "idle") {
              children.push(
                <li className="report-Item item-Release" key={(i += 1)}>
                  <i className="fa fa-user-times" />
                  <IdleWorker key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "volover") {
              children.push(
                <li className="report-Item item-VolOvertime" key={(i += 1)}>
                  <i className="fa fa-clock-o" />
                  <VoluntaryOvertime
                    key={(i += 1)}
                    properties={comp.Properties}
                  />
                </li>
              );
            } else if (comp.ComponentName === "reassign") {
              children.push(
                <li className="report-Item item-Reassign" key={(i += 1)}>
                  <i className="fa fa-retweet" />
                  <Reassign key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "trainingindv") {
              children.push(
                <li className="report-Item item-Reassign" key={(i += 1)}>
                  <i className="fa fa-retweet" />
                  <IndvTraining key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "trainingteam") {
              children.push(
                <li className="report-Item item-Reassign" key={(i += 1)}>
                  <i className="fa fa-retweet" />
                  <TeamTraining key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "indvreward") {
              children.push(
                <li className="report-Item item-Reassign" key={(i += 1)}>
                  <i className="fa fa-retweet" />
                  <IndvReward key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "rewardteam") {
              children.push(
                <li className="report-Item item-Reassign" key={(i += 1)}>
                  <i className="fa fa-retweet" />
                  <TeamReward key={(i += 1)} properties={comp.Properties} />
                </li>
              );
            } else if (comp.ComponentName === "volover") {
              children.push(
                <li className="report-Item item-Reassign" key={(i += 1)}>
                  <i className="fa fa-retweet" />
                  <h3> {comp.properties.worker} worked voluntary overtime. </h3>
                </li>
              );
            }
          }
        }
      }
      return children;
    };

    return (
      <>
        {/* {this.props &&
          this.props.username &&
          (this.props.username.toLowerCase().startsWith("paccissuser") ||
            this.props.username.toLowerCase().startsWith("paccisstest") ||
            this.props.username.toLowerCase() === "blondie" ||
            this.props.username.toLowerCase() === "samirspl") && ( */}
        <Row>
          <Col lg="12">
            <TestPanel />
          </Col>
        </Row>
        {/* )} */}
        <Row>
          <Col lg="6">
            <Card>
              <CardHeaderBold>Overall Progress</CardHeaderBold>
              <CardBody>
                <div className="progress">
                  <div
                    style={{ width: renderPctCompleteWidth() }}
                    aria-valuemax="100"
                    aria-valuemin="0"
                    aria-valuenow={this.state.pctComplete}
                    role="progressbar"
                    className="progress-bar progress-bar-default"
                  />
                </div>

                {this.props.runWeekResponseState !== "eow" &&
                this.props.runWeekResponseState !== "eop" ? (
                  <Button
                    id={`runweekbutton-${this.state.runWeekButtonDisabled.toString()}`}
                    onClick={this.runTheWeek}
                    disabled={this.state.runWeekButtonDisabled}
                    color={this.props.runStarted ? "link" : "primary"}
                  >
                    {this.renderButtonText()}
                    {(this.props.runStarted === true && !this.props.hasError) ||
                    this.state.initialLoad ? (
                      <i className="fa fa-refresh fa-spin fa-3x fa-fw" />
                    ) : (
                      ""
                    )}
                  </Button>
                ) : (
                  <span />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <WorkWeekInfo />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card className="component-Alerts">
              <CardHeaderBold>Communications</CardHeaderBold>
              <CardBody>{this.showContent()}</CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeaderBold>
                {`Status Report: Week ${this.props.displayProjectWeek}`}
              </CardHeaderBold>
              <CardBody className="mh-0">
                <Row className="row row-StatusReport">
                  <Col lg="2">
                    <Card>
                      <CardHeaderBold>Monday</CardHeaderBold>
                      <CardBody>
                        <ul className="report-List">
                          {renderChildren("Monday")}
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2">
                    <Card>
                      <CardHeaderBold>Tuesday</CardHeaderBold>
                      <CardBody>
                        <ul className="report-List">
                          {renderChildren("Tuesday")}
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2">
                    <Card>
                      <CardHeaderBold>Wednesday</CardHeaderBold>
                      <CardBody>
                        <ul className="report-List">
                          {renderChildren("Wednesday")}
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2">
                    <Card>
                      <CardHeaderBold>Thursday</CardHeaderBold>
                      <CardBody>
                        <ul className="report-List">
                          {renderChildren("Thursday")}
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="2">
                    <Card>
                      <CardHeaderBold>Friday</CardHeaderBold>
                      <CardBody>
                        <ul className="report-List">
                          {renderChildren("Friday")}
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <footer>
                  <Link
                    to="/reports/history"
                    href="/reports/history"
                    className="btn btn-outline-secondary"
                    disabled={this.state.hideFullReportLink}
                  >
                    View Full Week {this.state.currentProjWeek} Report
                    {/* // TODO CHECK THIS */}
                  </Link>
                </footer>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  username: state.auth.user.UserName,
  classroomId:
    state.auth.studentInfo && state.auth.studentInfo.ClassroomId
      ? state.auth.studentInfo.ClassroomId
      : 0,
  studentRunWeekReturnObject:
    state.execution.workweek.studentRunWeekReturnObject,
  // project: state.project,
  currentRunInfo: getCurrentRunInfo(state),
  autorun: state.execution.workweek.autorun,
  workerAssignmentList: state.project.workerAssignmentList,
  runStarted: state.execution.workweek.runStarted,
  runWeekResponseState: state.execution.workweek.runWeekResponseState,
  runDay: state.project.projectStatus.DayNumber,
  projectStatus: state.project.projectStatus,
  isRunningDay: state.project.projectStatus.IsRunningDay,
  weekHasCompleted: state.project.projectStatus.WeekHasCompleted,
  haveCondition: state.execution.workweek.haveCondition,
  conditionOpen: state.execution.workweek.conditionOpen,
  conditionId: state.execution.workweek.conditionId,
  haveReassign: state.execution.workweek.haveReassign,
  reassignOpen: state.execution.workweek.reassignOpen,
  selectedResponse: state.execution.workweek.selectedResponse,
  havePlayScript: state.execution.workweek.havePlayScript,
  playScriptOpen: state.execution.workweek.playScriptOpen,
  playScriptList: state.execution.workweek.playScriptList,
  runId: state.project.runId,
  maxWeeks: state.project.maxWeeks,
  currentProjectWeek: state.project.currentProjectWeek,
  displayRunDay: state.execution.workweek.runDay,
  currentWorkWeek: state.execution.workweek.currentRunWeek, // this represents actual runWeek while workweek hasn't finished
  displayProjectWeek: state.project.displayProjectWeek,
  projectedFinishWeek: state.project.projectedFinishWeek,
  tasks: state.project.tasks,
  currentBudget: state.project.budgetList,
  workers: state.project.workers,
  skills: state.project.skills,
  condition: state.execution.workweek.condition,
  workWeekPctComplete: state.execution.workweek.workWeekPctComplete,
  currentRunWeek: state.project.currentProjectWeek, 
  historySummary: state.execution.workweek.historySummary,
  staffingProblemList: state.execution.workweek.staffingProblemList,
  haveStaffingProblem: state.execution.workweek.haveStaffingProblem,
  testConditionList: state.execution.workweek.testConditionList,
  classRuns: state.instructor.classRuns,
  hasError: state.execution.workweek.hasError,
  errorMessage: state.execution.workweek.errorMessage,
  errorStacktrace: state.execution.workweek.errorStacktrace,
  firstLevelTabIndex: state.ui.firstLevelTabIndex,
});

const mapDispatchToProps = (dispatch) => ({
  runTheWeek: (runId, conditionId, runDay, runWeek) =>
    dispatch(runTheWeek(runId, conditionId, runDay, runWeek)),
  getPresentationRunDay: (runId) => dispatch(getPresentationRunDay(runId)),
  resetWorkWeek: () => dispatch(resetWorkWeek()),
  processAssignments: (data, updateItem, strValue, intValue) =>
    dispatch(processAssignments(data, updateItem, strValue, intValue)),
  processConditionResponse: (runId, conditionId, responseId, runWeek, runDay) =>
    dispatch(
      processConditionResponse(runId, conditionId, responseId, runWeek, runDay)
    ),
  fetchClassRuns: (cId) => dispatch(fetchClassRuns(cId)),
  processReassignResponse: (
    runId,
    conditionId,
    responseId,
    workerId,
    runWeek,
    runDay
  ) =>
    dispatch(
      processReassignResponse(
        runId,
        conditionId,
        responseId,
        workerId,
        runWeek,
        runDay
      )
    ),
  acceptWeeklyStaffingPlan: (runId, weekNumber) =>
    dispatch(acceptWeeklyStaffingPlan(runId, weekNumber)),
  checkForStaffingProblems: (runId, weekNumber) =>
    dispatch(checkForStaffingProblems(runId, weekNumber)),
  fetchRuns: (userId) => dispatch(fetchRuns(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkWeek);
