/* eslint-disable max-len */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faUserCog,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";

export const Charter = (props) => (
  <div className="charter-div style-background-components">
    <header>
      <h2>Project Charter</h2>
    </header>
    <p>
      <FontAwesomeIcon icon={faBookmark} className="icons-presentation" />
      <strong>Project:</strong> Alliance Prototype
      <br />
      <FontAwesomeIcon icon={faFemale} className="icons-presentation" />
      <strong>Customer:</strong> Kristine Olson, VP Market Development
      <br />
      <FontAwesomeIcon icon={faUserCog} className="icons-presentation" />
      <strong>Sponsor:</strong> B. D. Smith, President & COO
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Description
    </h4>
    <p>
      The Alliance Launch project will generate the marketing materials that
      will be used to advertise the new Uniworld e-commerce web site. The
      customer of this marketing material effort is the sales and marketing
      organization of Uniworld. The end user is Uniworld’s current and
      prospective product customers.
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Deliverables
    </h4>
    <p>
      The Alliance Launch Product is to be composed of printed marketing
      materials for advertisement and a tutorial that will teach current and
      prospective end users how to navigate and use the new web site for
      researching and ordering products from the Uniworld web site.
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Performance
    </h4>
    <p>
      The project will be deemed to have met quality requirements when system
      testing verifies there are less than 20 total defects in the tutorial and
      draft printed materials. The tutorial must be capable of installing and
      launching in less than 10 seconds on all the major PC and Macintosh
      platforms.
    </p>
    <footer>
      <p>ID #1015</p>
    </footer>
  </div>
);
