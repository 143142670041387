import { combineReducers } from "redux";

import { routerReducer as router } from "react-router-redux";
import modal from "redux/Modal/ModalModule";
import auth from "redux/Authentication/reducer";
import notification from "redux/Notification/NotificationModule";
import project from "redux/Project/reducer";
import report from "redux/Report/reducer";
import risk from "redux/Risk/reducer";
import resource from "redux/Resource/reducer";
import stakeholder from "redux/Stakeholder/reducer";
import training from "redux/Training/reducer";
import workweek from "redux/WorkWeek/reducer";
import interactions from "redux/Interaction/reducer";
import instructor from "redux/Instructor/reducer";
import uiReducer from "redux/UI/reducer";
import app from "../redux/reducer";

export const rootReducer = asyncReducers =>
  combineReducers({
    app,
    notification,
    modal,
    auth,
    project,
    report,
    router,
    instructor,
    ui: uiReducer,
    planning: combineReducers({
      risk,
      resource,
      stakeholder,
      training
    }),
    execution: combineReducers({
      workweek,
      interactions
    }),
    // dialogs: dialogReducer,
    // form: formReducer,
    ...asyncReducers
  });

/* eslint-disable no-param-reassign */
export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(rootReducer(store.asyncReducers));
};
/* eslint-enable */

export default rootReducer;
