import { UPDATE_FIRST_LEVEL_TAB_INDEX } from './actions';

const initialState = {
  //For accessbility purposes without mouse,
  //First level is for the menu and sidebar, and sub-components can add to the inner hiarachy sub-tabIndexes.
  //Each first level have a range of 100 sub-indexes to assign.
  firstLevelTabIndex: 0, 
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIRST_LEVEL_TAB_INDEX:
      return {
        ...state,
        firstLevelTabIndex: action.tabIndex,
      };
    default:
      return state;
  }
};

export default uiReducer;
