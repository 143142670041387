import React, { useState, useEffect, Fragment } from "react";
import {
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  Button,
  Badge,
  UncontrolledTooltip
} from "reactstrap";
import { connect } from "react-redux";
import { Offline, Online } from "react-detect-offline";
import { helpCheckBool } from "redux/Authentication/selectors";
import { getUnreadEmailCount } from "redux/Project/selectors";
import { openModal } from "redux/Modal/ModalModule";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faListAlt
} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "components/PageHeader/PageHeader";
import "./Header.scss";

import {
  REFERENCE_MODAL,
  HELP_DOCUMENTATION_MODAL,
  EMAIL_MODAL,
  ORIENTATION_MODAL
} from "routes/Modal/ModalTypes";

import { logout, updateCheckListItem } from "redux/Authentication/thunks";
import BuildVersion from "components/BuildVersion";

import "./Header.css";

const Header = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    document.body.classList.add("aside-menu-hidden");
  }, []);

  const sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
    setShowSidebar(!showSidebar);
  };

  const sidebarMinimize = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  };

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
    setShowSidebar(!showSidebar);
  };

  const asideToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  };

  const showReferences = (evt) => {
    evt.preventDefault();
    props.showReferences();
  };

  const showHelp = () => {
    if (props.location.pathname === "/welcome") {
      if (props.helpCheckBool === false) {
        for (let i = 0, l = props.auth.checkListItems.length; i < l; i++) {
          if (
            props.auth.checkListItems[i].ControlNumber === 4 &&
            props.auth.checkListItems[i].ControlValue === 0
          ) {
            props.updateCheckListItem(
              props.auth.userId,
              4,
              "VIEW_HELP",
              true
            );
            break;
          }
        }
      }
    }
    props.showHelpModal();
  };

  const showOrientationModal = () => {
    props.showOrientationModal();
  };

  const showEmail = () => {
    props.showEmailModal();
  };

  //Header items are the last to receive the tab focus, therefore starting with 100k
  let headerTabIndex = 100000;

  return (
    <header className="app-header navbar" tourstop="navbar">
      <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle} tabIndex={headerTabIndex++}>
        {showSidebar ? (
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        ) : (
          <FontAwesomeIcon icon={faChevronCircleRight} />
        )}
      </NavbarToggler>
      <NavbarBrand href="#" tag="div" tabIndex={headerTabIndex++} />
      <NavbarToggler className="d-md-down-none" onClick={sidebarToggle} tabIndex={headerTabIndex++}>
        {showSidebar ? (
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        ) : (
          <FontAwesomeIcon icon={faChevronCircleRight} />
        )}
      </NavbarToggler>

      <Nav className="ml-auto mr-3" navbar>

        <NavItem>
          <UncontrolledTooltip
            id="notett"
            placement="top"
            target="noteLink"
            delay={{ hide: 900 }}
            tabIndex={headerTabIndex++}
          >
            Notification History
          </UncontrolledTooltip>
          <Link
            id="noteLink"
            className="btn btn-link"
            to="/notifications"
            href="/notifications"
            tabIndex={headerTabIndex++}
          >
            <i className="fa fa-bell" />
          </Link>
        </NavItem>

        {props.runId > 0 && props.auth.userType !== "Instructor" ? (
          <NavItem>
            <UncontrolledTooltip
              id="emailtt"
              placement="top"
              target="emailLink"
              trigger="hover"
              tabIndex={headerTabIndex++}
            >
              Email
            </UncontrolledTooltip>
            <Button
              id="emailLink"
              color="link"
              className="count-info"
              onClick={showEmail}
              tabIndex={headerTabIndex++}
            >
              <i className="fa fa-envelope" />
              <Badge color="primary">
                {props.unreadEmailCount > 0
                  ? props.unreadEmailCount
                  : ""}
              </Badge>
            </Button>
          </NavItem>
        ) : (
          <span />
        )}

        <NavItem>
          <UncontrolledTooltip
            id="projtt"
            placement="top"
            target="projLink"
            trigger="hover"
            tabIndex={headerTabIndex++}
          >
            The Project Reference Guide
          </UncontrolledTooltip>
          <Button id="projLink" color="link" onClick={showReferences} tabIndex={headerTabIndex++}>
            <i className="fa fa-book" />
          </Button>
        </NavItem>
        <NavItem>
          <UncontrolledTooltip
            id="helptt"
            placement="top"
            target="helpLink"
            trigger="hover"
            tabIndex={headerTabIndex++}
          >
            Help Documentation
          </UncontrolledTooltip>
          <Button id="helpLink" color="link" onClick={showHelp} tabIndex={headerTabIndex++}>
            <i className="fa fa-life-ring" />
          </Button>
        </NavItem>
        {props.auth.productId !== 50 && (
          <NavItem>
            <UncontrolledTooltip
              id="ortt"
              placement="top"
              target="orientationLink"
              trigger="hover"
              tabIndex={headerTabIndex++}
            >
              Stakeholder Orientation Guide
            </UncontrolledTooltip>
            <Button
              id="orientationLink"
              color="link"
              onClick={showOrientationModal}
              tabIndex={headerTabIndex++}
            >
              <i className="fa fa-users" />
            </Button>
          </NavItem>
        )}
        <NavItem tourstop="cl1">
          <UncontrolledTooltip
            id="checkt"
            placement="top"
            target="checklistLink"
            trigger="hover"
            tabIndex={headerTabIndex++}
          >
            Project Checklist
          </UncontrolledTooltip>
          <NavbarToggler
            id="checklistLink"
            className="d-md-down-none"
            type="button"
            onClick={asideToggle}
            tabIndex={headerTabIndex++}
          >
            <FontAwesomeIcon icon={faListAlt} />
          </NavbarToggler>
        </NavItem>
        {props.auth.userType !== "Instructor" ? (
          <NavItem tabIndex={headerTabIndex++}>{props.name}</NavItem>
        ) : (
          <span />
        )}
        {props.auth.userType === "Instructor" ? (
          <NavItem tabIndex={headerTabIndex++}>{props.auth.username}</NavItem>
        ) : (
          <span />
        )}
        <NavItem className="d-md-down-none">
          <NavLink onClick={props.logout} tag="button" tabIndex={headerTabIndex++}>
            <i className="fa fa-lock" /> Logout
          </NavLink>
        </NavItem>
        <NavItem tabIndex={headerTabIndex++}>
          <b>Exec#: {props.runId}</b>
        </NavItem>
      </Nav>
    </header>
  );
};

const mapStateToProps = (state) => ({
  helpCheckBool: helpCheckBool(state),
  unreadEmailCount: getUnreadEmailCount(state),
  auth: state.auth,
  runId: state.project.runId,
  displayProjectWeek: state.project.displayProjectWeek,
  projectedFinishWeek: state.project.projectedFinishWeek
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  showReferences: () => dispatch(openModal(REFERENCE_MODAL, {}, "modal-wide")),
  showHelpModal: () =>
    dispatch(openModal(HELP_DOCUMENTATION_MODAL, {}, "modal-wide")),
  showOrientationModal: () =>
    dispatch(openModal(ORIENTATION_MODAL, {}, "modal-wide")),
  showEmailModal: () => dispatch(openModal(EMAIL_MODAL, {}, "modal-1100")),
  updateCheckListItem: (userId, controlNumber, controlType, isSet) =>
    dispatch(updateCheckListItem(userId, controlNumber, controlType, isSet))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
