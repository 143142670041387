/* eslint-disable max-len */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faUserCog,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";

export const CharterPM = (props) => (
  <div className="charter-div style-background-components">
    <header>
      <h2>Project Charter</h2>
    </header>
    <p>
      <FontAwesomeIcon icon={faBookmark} className="icons-presentation" />
      <strong>Project:</strong> Alliance Prototype
      <br />
      <FontAwesomeIcon icon={faFemale} className="icons-presentation" />
      <strong>Customer:</strong> Kristine Olson, VP Market Development
      <br />
      <FontAwesomeIcon icon={faUserCog} className="icons-presentation" />
      <strong>Sponsor:</strong> B. D. Smith, President & COO
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Description
    </h4>
    <p>
      The Alliance Launch project will generate the marketing materials that
      will be used to advertise the new Uniworld e-commerce web site. The
      customer of this marketing material effort is the sales and marketing
      organization of Uniworld. The end user is Uniworld’s current and
      prospective product customers.
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Deliverables
    </h4>
    <p>
      The deliverables for this project are:
      <ul>
        <li>Marketing materials</li>
        <li>Tutorial</li>
        <li>Mailing materials</li>
      </ul>
      This project is not expected to:
      <ul>
        <li>Produce the end user mailing list</li>
        <li>Actually do the printing or mailing</li>
        <li>Produce the actual Alliance web site design</li>
        <li>Perform follow-on analysis</li>
        <li>Perform follow-on marketing or sales</li>
      </ul>
    </p>
    <h4>
      <FontAwesomeIcon icon={faAngleRight} className="icons-presentation" />
      Performance
    </h4>
    <p>
      The project will be deemed to have met quality requirements when system
      testing verifies there are less than 20 total defects in the tutorial and
      draft printed materials. The tutorial must be capable of installing and
      launching in less than 10 seconds on all the major PC and Macintosh
      platforms.
      <br />
      Cordially, <br />
      B. D. Smith, President & COO
    </p>
  </div>
);
